


























import { Prop, Vue } from 'vue-property-decorator';
import { buildConfig } from '../config';

export default class NavComponent extends Vue {
  @Prop({ default: 'Skyhub' })
  private title!: string;
  private consoleRedirectURL = buildConfig(process.env).consoleRedirectURL;
}
