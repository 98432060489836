var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "z-0 flex items-center justify-between px-6 py-3 bg-black shadow flex-wrap-no-wrap"
    },
    [
      _c("img", {
        staticClass: "inline-block text-white",
        attrs: { src: require("../../public/skyhub_mark.svg"), alt: "" }
      }),
      _c(
        "div",
        { staticClass: "ml-4 mr-6 text-white" },
        [
          _c("router-link", { attrs: { to: "/", active: false } }, [
            _c("span", { staticClass: "text-xl tracking-tight" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "flex items-center" }, [
        _c(
          "div",
          { staticClass: "inline-block text-left text-white items-right" },
          [
            _c("button", [
              _c("a", { attrs: { href: _vm.consoleRedirectURL } }, [
                _c("div", [_vm._v("Go to console")])
              ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }