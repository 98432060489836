








import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/NavComponent.vue";

@Component<App>({
  components: {
    Navigation,
  },
})
export default class App extends Vue {}
