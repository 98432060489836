/* eslint-disable @typescript-eslint/no-explicit-any */
interface IConfig {
  buildStage: string;
  consoleRedirectURL: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function buildConfig(env: any): IConfig {
  return {
    buildStage: env.VUE_APP_STAGE,
    consoleRedirectURL: env.VUE_APP_FIREBASE_CONSOLE_REDIRECT_URL
  };
}

export default buildConfig(process.env);

export { IConfig, buildConfig };
